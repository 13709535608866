<template>
	<overlay-scrollbars class="page page-accounts" ref="os">
		Settings
		<div class="row">
			<div @click="showChangePassword" class="btn btn_change_password">Change password</div>
		</div>
	</overlay-scrollbars>
</template>

<script>
export default {
	methods: {
		showChangePassword () {
			this.$store.commit('setModal', { template: 'change-password' })
		}
	}
}
</script>

<style lang="scss" scoped>
.btn {
	&_change_password {
		padding: 1.25rem 3.5rem;
		margin: 1.65rem 0rem;
		background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
		box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
		border-radius: 6px;
		display: inline-block;
	}
}
</style>
